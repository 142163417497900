<template>
  <div id="pro" dir="ltr">
    <div class="content_box">
      <div class="inner_max">
        <component :is="currentComponent"></component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpgradeToPro',
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    currentComponent() {
      return () => this.$platform.componentLoader(this.regulator, 'wholesale/UpgradeToPro', true).component;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/pro.scss';
</style>
